<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="getConsumptionRecord">
                    <v-card :elevation="1" color='grey lighten-3'>
                        <v-card-text class="py-0">
                            <v-row dense>
                                <v-col cols="2">
                                    <v-row no-gutters style="margin-top: 3px;color: #000;">
                                        <v-col cols="3">Type</v-col>
                                        <v-col cols="9">
                                            <v-combobox 
                                                v-model="searchType"
                                                dense
                                                outlined
                                                hide-details
                                                :items="['All', 'By Employee']"
                                            >
                                            </v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="3"  v-if="searchType == 'By Employee'">
                                    <v-row no-gutters style="margin-top: 3px;color: #000;">
                                        <v-col cols="4">Employee</v-col>
                                        <v-col cols="8">
                                            <v-combobox 
                                                dense
                                                outlined
                                                hide-details
                                                v-model="employee"
                                                @focus="$store.dispatch('employee/getEmployees')"
                                                :items="$store.getters['employee/employees']"
                                                item-text="display_text"
                                                item-value="id"
                                            >
                                            </v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="6">
                                    <v-row dense style="margin-top: -2px;">
                                        <v-col cols="6">
                                            <v-row no-gutters>
                                                <v-menu>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            hide-details
                                                            v-model="consumption.dateFrom"
                                                            label="Form Date"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                        <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="consumption.dateFrom"></v-date-picker>
                                                </v-menu>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="consumption.dateTo"
                                                        label="To Date"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="consumption.dateTo"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn height="30px" type="submit" dark color="light-blue darken-2" :loading="searchLoading">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense v-if="show">
            <v-col cols="12">
                <v-btn height="26px" v-on:click.prevent="print">Print</v-btn>
            </v-col>
            <v-col cols="12" id="invoiceContent">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Sl</th>
                                <th>Invoice No</th>
                                <th>Assign Date</th>
                                <th>Assign By</th>
                                <th>Assign To</th>
                                <th>Note</th>
                                <th class="hide">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(consumption, ind) in $store.getters['consumption/consumptions']" :key="ind">
                                <td class="text-center">{{ ind + 1}}</td>
                                <td>{{ consumption.invoice_id }}</td>
                                <td>{{ consumption.assign_date }}</td>
                                <td>{{ consumption.added_by ? consumption.added_by.name : "" }}</td>
                                <td>{{ consumption.employee ? consumption.employee.name : "" }}</td>
                                <td>{{ consumption.note }}</td>
                                <td style="width:8%" class="text-center hide">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon x-small @click="printInvoice(consumption.id)" v-on="on">mdi-printer</v-icon>
                                        </template>
                                        <span>Print</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon x-small @click="$router.push(`/consumption/${consumption.id}`)" color="success" v-on="on">mdi-circle-edit-outline</v-icon>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon x-small @click="consumptionId = consumption.id;$refs.confirmDialog.dialog = true" color="error" v-on="on">mdi-delete-circle-outline</v-icon>
                                        </template>
                                        <span>Delete</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <confirm-dialog ref="confirmDialog" @confirm="deleteConsumption"></confirm-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import confirmDialog from "../../../components/Confirm";
export default {
    name: 'ConsumptionRecord',

    components: {
		"confirm-dialog": confirmDialog,
    },

    data: ()=> ({
        searchType: null,

        consumption: {
            employee_id: null,
            dateFrom: new Date().toISOString().substr(0, 10),
            dateTo: new Date().toISOString().substr(0, 10),
        },
        consumptionRecord: [],

        supplier: null,
        employee: null,
        searchLoading: false,

        consumptionId: null,
        show: false,
        apiBasePath: window.apiBaseURL,
    }),

    watch: {
        employee(employee) {
            if(employee == null) return
            this.consumption.employee_id = employee.id;
        },
        
    },

    created() {
        this.setStyle();
    },

    methods: {
        getConsumptionRecord() {

            this.searchLoading = true;

            this.$store.dispatch('consumption/getConsumptions', { apiArg: this.consumption });

            this.searchLoading = false;

            this.show = true;
        },

        printInvoice(id) {
            this.$router.push(`/consumption-invoice/${id}`);
        },

        deleteConsumption() {
            this.$store.dispatch('consumption/deleteConsumption', { id: this.consumptionId });
            this.$refs.confirmDialog.dialog = false;
        },

        async print(){
            let invoiceContent = document.querySelector('#invoiceContent').innerHTML;
            let printWindow = window.open('', 'PRINT', `width=${screen.width}, height=${screen.height}, left=0, top=0`);
            let companyProfile = this.$store.getters['companyProfile/company']
            let companyImage = companyProfile != null ? this.$store.getters['companyProfile/company'].image : ''
            let ImagePath = companyImage ? this.apiBasePath + '/' + companyImage : '';
            printWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <title>Investigation Payment Slip</title>
                    <style>
                    </style>
                </head>
                <body>
                    <div>
                        <div class="container">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-2">
                                                    <img src="`+ImagePath+`" alt="Logo" style="height:90px; width:90%;object-fit: contain;" />
                                                </div>
                                                <div class="col-xs-10">
                                                    <strong style="font-size:18px;">${companyProfile != null ? companyProfile.company_name : ''}</strong><br>
                                                    <p style="white-space:pre-line;margin:0px">Address : ${companyProfile != null ? companyProfile.address : ''}</p>
                                                    <p style="margin:0px">Mobile : ${companyProfile != null ? companyProfile.contact_us : ''}</p>
                                                    <p style="margin:0px">E-Mail : ${companyProfile != null ? companyProfile.email : ''}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="invoice-title">
                                                    Consumption Record
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-12">
                                                    ${invoiceContent}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div style="width:100%;height:50px;">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>    
                    
                </body>
                </html>
            `);
            
            let invoiceStyle = printWindow.document.createElement('style');
            invoiceStyle.innerHTML = this.style.innerHTML;
            printWindow.document.head.appendChild(invoiceStyle);
            printWindow.moveTo(0, 0);
            
            printWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            printWindow.print();
            printWindow.close();
        },

        setStyle(){
            this.style = document.createElement('style');
            this.style.innerHTML = `
                .custom-row {
                    width: 100%;
                    display: block;
                }
                .print-btn a{
                    background: #CFD8DC;
                    display: inline-block;
                    padding: 3px 13px;
                    border-radius: 5px;
                    color: #000 !important;
                }
                .print-btn a:hover {
                    background: #B0BEC5;
                }
                .invoice-title {
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    margin-bottom: 15px;
                    padding: 5px;
                    border-top: 1px dotted #454545;
                    border-bottom: 1px dotted #454545;
                }
                .col-xs-12 {
                    width: 100%;
                }
                .col-xs-10 {
                    width: 80%;
                    float: left;
                }
                .col-xs-9 {
                    width: 70%;
                    float: left;
                }
                .col-xs-7 {
                    width: 60%;
                    float: left;
                }
                .col-xs-6 {
                    width: 50%;
                    float: left;
                }
                .col-xs-5 {
                    width: 40%;
                    float: left;
                }
                .col-xs-4 {
                    width: 35%;
                    float: left;
                }
                .col-xs-3 {
                    width: 30%;
                    float: left;
                }
                .col-xs-2 {
                    width: 20%;
                    float: left;
                }
                .b-text {
                    font-weight: 500;
                    font-size: 15px;
                }
                .normal-text {
                    font-size: 14px;
                    margin: 0px;
                }
                .invoice-details {
                    width: 100%;
                    border-collapse: collapse;
                    border:1px solid #ccc;
                }
                .invoice-details thead {
                    font-weight: 500;
                    text-align:center;
                }
                .invoice-details tbody td{
                    padding: 0px 5px;
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .line {
                    border-bottom: 1px solid #ccc;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .paid-text {
                    padding:30px 0px;
                }
                .mt-60 {
                    margin-top: 60px;
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .ml-20 {
                    margin-left: 20px;
                }
                .ft-fiext {
                    position: fixed;
                    bottom: 0;
                }

                @media print {
                    .hide {
                        display: none
                    }
                    .v-data-table > .v-data-table__wrapper > table {
                        width: 100%;
                        border-spacing: 0;
                    }
                    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
                        font-size: 13px;
                        padding: 0px 1px;
                        color: #000;
                        border: 1px solid #ccc !important;
                        text-align: center;
                        height: 24px !important;
                    }
                    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
                        height: 20px !important;
                        padding: 0px 5px !important;
                        font-size: 12px !important;
                        border: 1px solid #ccc !important;
                    }
                }
                .cls {
                    clear: both;
                }
            `;
            document.head.appendChild(this.style);
        },
    }
}
</script>

<style lang="scss" scoped>
   .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
   }
   .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        font-size: 11px;
        padding: 0px 1px;
        background: #607D8B;
        color: #fff;
        border: 1px solid #ccc;
        text-align: center;
        height: 24px !important;
   }
   .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 20px !important;
        padding: 0px 5px !important;
        font-size: 12px !important;
        border: 1PX solid #ccc !important;
   }
</style>